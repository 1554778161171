<template>
  <div>
    <b-container fluid>
      <b-row>
        <div class="date-selection mt-3">
          <span class="mr-2">{{ $t("queryhistorydata.selectTime") }}:</span>
          <datePicker :format="format" v-model="selectedDate" />
        </div>
      </b-row>
      <highcharts :options="chartOptions" class="mt-5"></highcharts>
    </b-container>
  </div>
</template>
<script>
import datePicker from 'vuejs-datepicker'
export default {
  name: 'queryhistorydata',
  components: {
    datePicker
  },
  data () {
    return {
      chartOptions: {
        series: [
          {
            data: [1, 2, 3]
          }
        ]
      },
      format: 'yyyy-MM-dd',
      selectedDate: null
    }
  },
  computed: {
    formatDateObj () {
      return this.selectedDate.getDate() // 轉換成日期
    }
  }
}
</script>
<style lang="scss" scoped>
.date-selection{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
</style>
